:root {
	--amplify-primary-color: #0ab472;
	--amplify-primary-shade: #0ab472;
	--amplify-primary-tint: #0ab472;
	font-size: 16px;
}

html,
body {
	height: 100%;
}
